import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
// import Dashboard from "../pages/Dashboard/index"

//Articles
import Articles from "../pages/Articles"
import AddArticle from "../pages/Articles/AddArticle"
import ArticleDetails from "../pages/Articles/ArticleDetails"

//Categories
import Categories from "../pages/Categories"

//Tags
import Tags from "../pages/Tags"

//Keywords
import Keywords from "../pages/Keywords"

//Library
import Library from "../pages/Media/Library"
import AddLibrary from "../pages/Media/AddLibrary"

//Airdrops
import Airdrops from "../pages/Airdrops"

//Request
import Requests from "../pages/Requests"
import RequestDetails from "../pages/Requests/RequestDetails"

//Hubs
import Hubs from "../pages/Hubs"
import HubDetails from "../pages/Hubs/HubDetails"

const adminRoutes = [
  // { path: "/dashboard", component: Dashboard },

  //Request
  { path: "/request/:requestId", component: RequestDetails },
  { path: "/requests", component: Requests },

  //Hubs
  { path: "/hubs", component: Hubs },
  { path: "/hub/:hubId", component: HubDetails },

  //Articles
  { path: "/articles", component: Articles },
  { path: "/add-article", component: AddArticle },
  { path: "/article/:slug", component: ArticleDetails },

  //Categories
  { path: "/categories", component: Categories },

  //Tags
  { path: "/tags", component: Tags },

  //Keywords
  { path: "/keywords", component: Keywords },

  //Library
  { path: "/library", component: Library },
  { path: "/add-library", component: AddLibrary },

  //Airdrops
  { path: "/airdrops", component: Airdrops },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/articles" /> },
]

const ScienceRoutes = [
  //Hubs
  { path: "/hubs", component: Hubs },
  { path: "/hub/:hubId", component: HubDetails },
  //requests
  { path: "/request/:requestId", component: RequestDetails },
  { path: "/requests", component: Requests },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/requests" /> },
]

const MarketingRoutes = [
  //Articles
  { path: "/articles", component: Articles },
  { path: "/add-article", component: AddArticle },
  { path: "/article/:slug", component: ArticleDetails },

  //Categories
  { path: "/categories", component: Categories },

  //Tags
  { path: "/tags", component: Tags },

  //Keywords
  { path: "/keywords", component: Keywords },

  //Library
  { path: "/library", component: Library },
  { path: "/add-library", component: AddLibrary },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/articles" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },

  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },

  // // Authentication Inner
  // { path: "/pages-login", component: Login1 },
  // { path: "/pages-register", component: Register1 },
  // { path: "/page-recoverpw", component: Recoverpw },
  // { path: "/auth-lock-screen", component: LockScreen },
]

export { adminRoutes, ScienceRoutes, MarketingRoutes, authRoutes }
