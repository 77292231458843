import React, { createContext, useEffect, useReducer } from "react"
import { actionTypes } from "./actionTypes"

const appInitialState = {
  access_token: null,
  decoded_access_token: null,
  user_id: null,
}

const mainContext = createContext(appInitialState)

const ContextProvider = ({ children }) => {
  const [appState, dispatch] = useReducer((state, action) => {
    const { type } = action

    switch (type) {
      case actionTypes.SET_ACCESS_TOKEN:
        return {
          ...state,
          access_token: action.payload,
        }
      case actionTypes.SET_DECODED_ACCESS_TOKEN:
          return {
            ...state,
            decoded_access_token: action.payload,
          }
    }
  }, appInitialState)

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispatch({
        type: actionTypes.SET_ACCESS_TOKEN,
        payload: localStorage.getItem("access_token"),
      })
    }
  }, [localStorage.getItem("access_token")])

  return (
    <mainContext.Provider
      value={{
        appState,
        dispatch,
      }}
    >
      {children}
    </mainContext.Provider>
  )
}

export { mainContext, ContextProvider }
