import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { setBreadcrumbItems } from "../../store/actions"
import { getAllArticles, deleteArticle } from "client/ApiClient"
import SweetAlert from "react-bootstrap-sweetalert"

const Articles = props => {
  const { history } = props
  const [articles, setArticles] = useState([])

  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [deleteSlug, setDeleteSlug] = useState(null)

  const breadcrumbItems = [
    { title: "zarela", link: "#" },
    { title: "articles", link: "#" },
    { title: "list", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Articles", breadcrumbItems)
  }, [])

  useEffect(async () => {
    const response = await getAllArticles()
    if (response) {
      if (response.status === 200) {
        const res = response.data.articles
        setArticles(
          res.map((item, index) => {
            return {
              ...item,
              image: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_BASE_URL + "/" + item.cover}
                    width={80}
                    alt="cover"
                  />
                </div>
              ),
              actions: (
                <Row className="icon-demo-content Row justify-content-center align-items-center m-0 p-0">
                  <Col
                    xl="3"
                    lg="4"
                    sm="6"
                    className="m-0 p-0"
                    onClick={() => history.push("/article/" + item.slug)}
                  >
                    <i className="mdi mdi-square-edit-outline" />
                  </Col>
                  <Col xl="3" lg="4" sm="6" className="m-0 p-0">
                    <i
                      className="mdi mdi-delete"
                      onClick={() => {
                        setconfirm_alert(true)
                        setDeleteSlug(item.slug)
                      }}
                    />
                  </Col>
                </Row>
              ),
            }
          })
        )
      }
    }
  }, [confirm_alert])

  const deleteHandler = () => {
    deleteArticle(deleteSlug)
      .then(response => {
        if (response.status === 200) {
          setsuccess_msg(true)
          setsuccess_dlg(true)
          setdynamic_title("Deleted")
          setdynamic_description("Article has been deleted.")
          setconfirm_alert(false)
          setDeleteSlug(null)
        }
      })
      .catch(error => {
        setsuccess_msg(true)
        setsuccess_dlg(true)
        setdynamic_title("Error")
        setdynamic_description("Something went wrong")
        setconfirm_alert(false)
        setDeleteSlug(null)
      })
  }

  return (
    <>
      <MetaTags>
        <title>Zarela | Articles</title>
      </MetaTags>
      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false)
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteHandler()
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Articles List </CardTitle>
              <MDBDataTable
                striped
                bordered
                hover
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                responsive
                data={{
                  columns: [
                    {
                      label: "image",
                      field: "image",
                    },
                    {
                      label: "title",
                      field: "title",
                    },
                    {
                      label: "slug",
                      field: "slug",
                    },
                    {
                      label: "actions",
                      field: "actions",
                    },
                  ],
                  rows: articles,
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Articles)
