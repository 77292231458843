import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { setBreadcrumbItems } from "../../store/actions"
import { getAllRequests } from "client/ApiClient"

const Requests = props => {
  const { history } = props
  const [requests, setRequests] = useState({
    columns: [
      {
        label: "title",
        field: "title",
      },
      {
        label: "email",
        field: "email",
      },
      {
        label: "fullname",
        field: "fullname",
      },
      {
        label: "requiredfound",
        field: "requiredfound",
      },
      {
        label: "contributors",
        field: "contributors",
      },
    ],
  })

  const breadcrumbItems = [
    { title: "zarela", link: "#" },
    { title: "requests", link: "#" },
    { title: "list", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Requests", breadcrumbItems)
  }, [])

  useEffect(async () => {
    const response = await getAllRequests()
    if (response) {
      if (response.status === 200) {
        let data = response.data.requests
        data.forEach(
          e =>
            (e.clickEvent = () => {
              history.push(`/request/${e.id}`)
            })
        )
        setRequests(prev => ({ ...prev, rows: data }))
      }
    }
  }, [])

  return (
    <>
      <MetaTags>
        <title>Zarela | Requests</title>
      </MetaTags>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Requests List </CardTitle>
              <MDBDataTable hover responsive striped bordered data={requests} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Requests)
