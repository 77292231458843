import axios from "axios"
import jwt_decode from "jwt-decode"

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

request.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem("access_token")

    if (accessToken) {
      const decoded_access_token = jwt_decode(accessToken)
      if (decoded_access_token.exp < Date.now() / 1000) {
        window.location.href = "/"
        localStorage.clear()
        return Promise.reject("accessToken expired")
      }

      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  err => {
    console.log(err)
    window.location.href = "/"
    return Promise.reject(err)
  }
)

export default request
