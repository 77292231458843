import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { MDBDataTable, MDBCol, MDBCard, MDBCardBody } from "mdbreact"
import { setBreadcrumbItems } from "../../store/actions"
import {
  createAirdrop,
  getAllParticipants,
  zipAndDownloadAllCodes,
} from "client/ApiClient"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import fileDownload from "js-file-download"

const Airdrops = props => {
  const [isCountValid, setIsCountValid] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [participents, setParticipents] = useState([])

  useEffect(async () => {
    const response = await getAllParticipants()
    if (response) {
      if (response.status === 200) {
        const res = response.data.participants
        setParticipents(
          // res
          res.map((item, index) => {
            return {
              ...item,
              index: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100px",
                  }}
                >
                  {index + 1}
                </div>
              ),
              qrCode: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={item.qrCode} width={80} alt="qrCode" />
                  <span>{item.qrCode.split("/")[4]}</span>
                </div>
              ),
              token: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100px",
                  }}
                >
                  {item.token}
                </div>
              ),
              accountAddress: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100px",
                  }}
                >
                  {item.accountAddress}
                </div>
              ),
              email: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100px",
                  }}
                >
                  {item.email}
                </div>
              ),
              used: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100px",
                  }}
                >
                  {item.used.toString()}
                </div>
              ),
            }
          })
        )
      }
    }
  }, [showSuccessMessage])

  const breadcrumbItems = [
    { title: "zarela", link: "#" },
    { title: "AirDrops", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("AirDrops", breadcrumbItems)
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    var Count = document.getElementById("count").value
    if (Count === "") {
      setIsCountValid(false)
    } else {
      setIsCountValid(true)
    }

    var d1 = document.getElementsByName("validate")
    for (var i = 0; i < d1.length; i++) {
      d1[i].style.display = "block"
    }

    if (isCountValid) {
      setIsLoading(true)
      const response = await createAirdrop({
        count: Count,
      })

      if (response) {
        if (response.status === 201) {
          setShowSuccessMessage(true)
          setSuccessMessage("Airdrop created successfully")
          setIsLoading(false)
        } else {
          setShowErrorMessage(true)
          setErrorMessage(err.response.data.message)
          setIsLoading(false)
        }
      }
    }
  }

  const handleDownload = async e => {
    e.preventDefault()
    zipAndDownloadAllCodes().then(res => {
      fileDownload(res.data, "codes.zip")
    })
  }

  return (
    <>
      <MetaTags>
        <title>Zarela | AirDrops</title>
      </MetaTags>
      <Row>
        <React.Fragment>
          {showSuccessMessage && (
            <SweetAlert
              confirmBtnText="Ok"
              confirmBtnBsStyle="success"
              title="Success"
              onConfirm={() => {
                setShowSuccessMessage(false)
              }}
            >
              {successMessage}
            </SweetAlert>
          )}
          {showErrorMessage && (
            <SweetAlert
              confirmBtnText="Ok"
              confirmBtnBsStyle="error"
              title="Error"
              onConfirm={() => {
                setShowErrorMessage(false)
              }}
            >
              {errorMessage}
            </SweetAlert>
          )}
        </React.Fragment>

        <Col xl={12}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Generate AirDrops</h4>
              <AvForm
                className="needs-validation"
                onSubmit={e => {
                  handleSubmit(e)
                }}
              >
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="count">Count</Label>
                      <AvField
                        name="name"
                        placeholder="How many Airdrops you want to generate"
                        type="number"
                        errorMessage="Enter a valid count"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          maxLength: {
                            value: 2,
                            errorMessage:
                              "max avalible airdrop count per generations is 99!",
                          },
                        }}
                        id="count"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="auto">
                    <Button color="primary" type="submit" disabled={isLoading}>
                      Generate AirDrops
                    </Button>
                  </Col>

                  <Col xs="auto">
                    <Button
                      color="secondary"
                      type="button"
                      onClick={e => handleDownload(e)}
                    >
                      download QrCodes
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12}>
          {participents.length > 0 && (
            <MDBCol md="12">
              <MDBCard className="overflow-auto">
                <MDBCardBody>
                  <MDBDataTable
                    striped
                    bordered
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                      columns: [
                        {
                          label: "index",
                          field: "index",
                        },
                        {
                          label: "qrCode",
                          field: "qrCode",
                        },
                        {
                          label: "token",
                          field: "token",
                        },
                        {
                          label: "accountAddress",
                          field: "accountAddress",
                        },
                        {
                          label: "email",
                          field: "email",
                        },
                        {
                          label: "used",
                          field: "used",
                        },
                      ],
                      rows: participents,
                    }}
                    sortRows={["used"]}
                    materialSearch
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          )}
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Airdrops)
