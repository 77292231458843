import PropTypes from 'prop-types'
import React, { useEffect, useContext } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { mainContext } from 'store/context'
import { useHistory } from 'react-router-dom'

import { logoutUser } from "../../store/actions"
import { clearAccessToken } from './../../store/context/actions';

const Logout = props => {
  const { dispatch } = useContext(mainContext)
  useEffect(() => {
    props.logoutUser(props.history)
    clearAccessToken(dispatch)
  })

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func
}

export default withRouter(connect(null, { logoutUser })(Logout))
