import axios from "axios"

export const LOGIN_URL = process.env.REACT_APP_BASE_URL + "/api/admin/login"
export const REGISTER_URL = process.env.REACT_APP_BASE_URL + "/api/admin/signup"
export const REQUEST_PASSWORD_URL =
  process.env.REACT_APP_BASE_URL + "api/auth/forgot-password"
export const GET_ALL_ADMINS_URL = process.env.REACT_APP_BASE_URL + "/api/admin"
// export const MOBILE_VERIFICATION_URL = "api/auth/mobile-verification"

export const login = data => {
  return axios.post(LOGIN_URL, data)
}

export const signup = data => {
  return axios.post(REGISTER_URL, data)
}

export const requestPassword = email => {
  return axios.post(REQUEST_PASSWORD_URL, { email })
}

export const getAllAdmins = data => {
  return axios.get(GET_ALL_ADMINS_URL)
}
// export const mobileVerification = (password) => {
//     return axios.post(MOBILE_VERIFICATION_URL, { password })
// }
