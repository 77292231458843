import request from "./ApiSetup"

const CATEGORYS_URL = "/api/categories/"
const KEYWORDS_URL = "/api/keywords/"
const TAGS_URL = "/api/tags/"
const ARTICLES_URL = "/api/articles/"
const LIBRARY_URL = "/api/libraries/"
const AIRDROPS_URL = "/api/airdrops/"
const REQUESTS_URL = "/api/requests/"
const Hubs_URL = "/api/hubs/"

/**
 * Categories
 * @returns {Promise}
 */
// get all categories
export const getAllCategories = () => {
  return request.get(CATEGORYS_URL)
}
// create a new category
export const createCategory = data => {
  return request.post(CATEGORYS_URL, data)
}
// update category
export const updateCategory = (slug, data) => {
  return request.patch(`${CATEGORYS_URL}${slug}`, data)
}
//get category by slug
export const getCategoryBySlug = slug => {
  return request.get(`${CATEGORYS_URL}${slug}`)
}
//delete a category
export const deleteCategory = slug => {
  return request.delete(CATEGORYS_URL + slug)
}

/**
 * Tags
 * @returns {Promise}
 */
// get all tags
export const getAllTags = () => {
  return request.get(TAGS_URL)
}
//create a new tag
export const createTag = data => {
  return request.post(TAGS_URL, data)
}
//update tag
export const updateTag = (slug, data) => {
  return request.patch(`${TAGS_URL}${slug}`, data)
}
//get tag by slug
export const getTagBySlug = slug => {
  return request.get(`${TAGS_URL}${slug}`)
}
//delete a tag
export const deleteTag = tagId => {
  return request.delete(TAGS_URL + tagId)
}

/**
 * Keywords
 * @returns {Promise}
 */
// get all keywords
export const getAllKeywords = () => {
  return request.get(KEYWORDS_URL)
}
// create a new keyword
export const createKeyword = data => {
  return request.post(KEYWORDS_URL, data)
}
// update keyword
export const updateKeyword = (slug, data) => {
  return request.patch(`${KEYWORDS_URL}${slug}`, data)
}
//get keyword by slug
export const getKeywordBySlug = slug => {
  return request.get(`${KEYWORDS_URL}${slug}`)
}
// delete a keyword
export const deleteKeyword = keywordId => {
  return request.delete(KEYWORDS_URL + keywordId)
}
/**
 * Articles
 * @returns {Promise}
 */
// get all articles
export const getAllArticles = () => {
  return request.get(ARTICLES_URL)
}
// create a new article
export const createArticle = data => {
  return request.post(ARTICLES_URL, data)
}
// delete an article
export const updateArticle = (articleSlug, data) => {
  return request.patch(ARTICLES_URL + articleSlug, data)
}
// delete a library
export const deleteArticle = articleSlug => {
  return request.delete(ARTICLES_URL + articleSlug)
}

// get single article
export const getArticle = articleSlug => {
  return request.get(ARTICLES_URL + articleSlug)
}

/**
 * Libraries
 * @returns {Promise}
 */
// create a new library
export const createMedia = data => {
  return request.post(LIBRARY_URL, data)
}
// get all libraries
export const getAllLibraries = () => {
  return request.get(LIBRARY_URL)
}

/**
 * Airdrops
 */
// generate Airdrops by count
export const createAirdrop = data => {
  return request.post(AIRDROPS_URL, data)
}

//get all participants
export const getAllParticipants = () => {
  return request.get(AIRDROPS_URL)
}

//zip all codes
export const zipAndDownloadAllCodes = async () => {
  const token = localStorage.getItem("access_token")
  const arr = token.split(".")

  return request.get(
    AIRDROPS_URL + "download/" + arr[0] + "/" + arr[1] + "/" + arr[2],
    {
      responseType: "blob",
    }
  )
}

//get all requests for grant
export const getAllRequests = () => {
  return request.get(REQUESTS_URL)
}
//get request by id
export const getRequestById = requestId => {
  return request.get(REQUESTS_URL + requestId)
}

export const downloadRequestFile = requestId => {
  return request.get(REQUESTS_URL + requestId + "/download", {
    responseType: "blob",
  })
}

//get all hubs
export const getAllHubs = () => {
  return request.get(Hubs_URL)
}
//get hub by id
export const getHubById = hubId => {
  return request.get(Hubs_URL + hubId)
}
//delete a hub
export const deleteHub = hubId => {
  return request.delete(Hubs_URL + hubId)
}
//get country by code
export const getCountryByCode = code => {
  return request.get(`/api/hubs/get-country-by-code/${code}`)
}
