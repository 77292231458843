import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { MDBDataTable, MDBCol, MDBCard, MDBCardBody } from "mdbreact"
import { setBreadcrumbItems } from "../../store/actions"
import {
  getAllCategories,
  createCategory,
  updateCategory,
  getCategoryBySlug,
  deleteCategory,
} from "client/ApiClient"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

const Categories = props => {
  const [categoryData, setCategoryData] = useState({})
  const [prevSlug, setPrevSlug] = useState("")
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [categories, setCategories] = useState([])

  const [confirm_alert, setconfirm_alert] = useState(false)
  const [dynamic_description, setdynamic_description] = useState("")

  const [formState, setFormState] = useState("create")

  useEffect(async () => {
    const response = await getAllCategories()
    if (response) {
      if (response.status === 200) {
        const res = response.data.categories
        setCategories(
          res.map(item => {
            return {
              ...item,
              actions: (
                <Row className="icon-demo-content Row justify-content-center align-items-center m-0 p-0">
                  <Col
                    xl="3"
                    lg="4"
                    sm="6"
                    className="m-0 p-0"
                    onClick={async () => {
                      setFormState("edit")
                      setPrevSlug(item.slug)
                      const res = await getCategoryBySlug(item.slug)
                      setCategoryData(res.data)
                    }}
                  >
                    <i className="mdi mdi-square-edit-outline" />
                  </Col>
                  <Col xl="3" lg="4" sm="6" className="m-0 p-0">
                    <i
                      className="mdi mdi-delete"
                      onClick={() => {
                        setconfirm_alert(true)
                        setPrevSlug(item.slug)
                      }}
                    />
                  </Col>
                </Row>
              ),
            }
          })
        )
      }
    }
  }, [showSuccessMessage])

  const breadcrumbItems = [
    { title: "zarela", link: "#" },
    { title: "categories", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Categories", breadcrumbItems)
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    var validationCategoryTitle = document.getElementById(
      "validationCategoryTitle"
    ).value
    var validationDescription = document.getElementById(
      "validationDescription"
    ).value
    var validationSlug = document.getElementById("validationCategorySlug").value

    var d1 = document.getElementsByName("validate")
    for (var i = 0; i < d1.length; i++) {
      d1[i].style.display = "block"
    }

    let response
    try {
      if (formState === "create") {
        response = await createCategory({
          title: validationCategoryTitle,
          description: validationDescription,
          slug: validationSlug,
        })
      } else {
        response = await updateCategory(prevSlug, {
          title: validationCategoryTitle,
          description: validationDescription,
          slug: validationSlug,
        })
      }

      if (response) {
        if (response.status === 201) {
          setdynamic_description("Category has been created successfully")
          setShowSuccessMessage(true)
          setCategoryData({
            title: "",
            description: "",
            slug: "",
          })
          setFormState("create")
        } else if (response.status === 200) {
          setdynamic_description("Category has been updated successfully")
          setShowSuccessMessage(true)
          setCategoryData({
            title: "",
            description: "",
            slug: "",
          })
          setFormState("create")
        }
      }
    } catch (err) {
      setErrorMessage(err.response.data.message)
      setShowErrorMessage(true)
    }
  }

  const deleteHandler = () => {
    deleteCategory(prevSlug)
    setdynamic_description("Category has been deleted successfully")
    setconfirm_alert(false)
    setShowSuccessMessage(true)
  }

  return (
    <>
      <MetaTags>
        <title>Zarela | Categories</title>
      </MetaTags>
      <Row>
        <React.Fragment>
          {showSuccessMessage && (
            <SweetAlert
              confirmBtnText="Ok"
              confirmBtnBsStyle="success"
              title="Success"
              onConfirm={() => {
                setShowSuccessMessage(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          )}
          {showErrorMessage && (
            <SweetAlert
              confirmBtnText="Ok"
              confirmBtnBsStyle="error"
              title="Error"
              onConfirm={() => {
                setShowErrorMessage(false)
              }}
            >
              {errorMessage}
            </SweetAlert>
          )}

          {confirm_alert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                deleteHandler()
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </React.Fragment>

        <Col xl={4}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">create new category form</h4>
              <AvForm
                className="needs-validation"
                onSubmit={e => handleSubmit(e)}
              >
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationCategoryTitle">
                        Category Title
                      </Label>
                      <AvField
                        name="name"
                        placeholder="Category Title"
                        type="text"
                        value={categoryData.title}
                        errorMessage="Enter Category Title"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="validationCategoryTitle"
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationCategorySlug">
                        Category Slug
                      </Label>
                      <AvField
                        name="slug"
                        placeholder="Category Slug"
                        type="text"
                        value={categoryData.slug}
                        errorMessage="Enter Category Slug"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="validationCategorySlug"
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        className="mb-3"
                        type="textarea"
                        label="Description "
                        name="description"
                        id="validationDescription"
                        rows="5"
                        value={categoryData.description}
                        placeholder="Enter Category Description"
                        errorMessage="This value is required."
                        validate={{
                          required: { value: true },
                          minLength: {
                            value: 10,
                            errorMessage: "Min 10 chars.",
                          },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Button color="primary" type="submit">
                  {formState === "edit" ? "Update Category" : "Create Category"}
                </Button>
                {formState === "edit" && (
                  <Button
                    color="secondary"
                    type="submit"
                    className="mx-2"
                    onClick={() => {
                      setFormState("create")
                      setCategoryData({
                        title: "",
                        slug: "",
                        description: "",
                      })
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </AvForm>
            </CardBody>
          </Card>
        </Col>
        <Col xs={8}>
          {categories.length > 0 && (
            <MDBCol md="12">
              <MDBCard className="overflow-auto">
                <MDBCardBody>
                  <MDBDataTable
                    striped
                    bordered
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                      columns: [
                        {
                          label: "title",
                          field: "title",
                        },
                        {
                          label: "slug",
                          field: "slug",
                        },
                        {
                          label: "description",
                          field: "description",
                        },
                        {
                          label: "actions",
                          field: "actions",
                        },
                      ],
                      rows: categories,
                    }}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          )}
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Categories)
