import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { setBreadcrumbItems } from "../../store/actions"
import { getAllLibraries } from "client/ApiClient"

const Library = props => {
  const [libraries, setLibraries] = useState({
    columns: [
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 150,
      },
      {
        label: "url",
        field: "file",
        sort: "asc",
        width: 150,
      },
    ],
  })

  const breadcrumbItems = [
    { title: "zarela", link: "#" },
    { title: "libraries", link: "#" },
    { title: "list", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Libraries", breadcrumbItems)
  }, [])

  useEffect(async () => {
    const response = await getAllLibraries()
    if (response) {
      if (response.status === 200) {
        let data = response.data.libraries
        setLibraries(prev => ({ ...prev, rows: data }))
      }
    }
  }, [])

  return (
    <>
      <MetaTags>
        <title>Zarela | Libraries</title>
      </MetaTags>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Libraies List </CardTitle>
              <MDBDataTable
                hover
                responsive
                striped
                bordered
                data={libraries}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Library)
